rb, rt {
  font-size: 1em;
  'z-index': -99;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div.texteditor {
  font-family: Courier, monospace;
  outline: 0px;
  min-height: 100px;
  line-height: 3.5em;
}

span.texteditor.missing {
  border: 2px dashed;
  border-radius: 8px;
  padding: 0px 8px;
  min-width: 200px;
  height: 2em;
  margin: 8px;
  vertical-align: middle;
  font-size: 1em;
  line-height: 1.8em;
  display: inline-block;
}

:root {
  --error-important-underline: var(--red);
  --error: #F3D3D9;
}

span.texteditor.missing:empty:focus::before,
span.texteditor.missing:empty::before{
  content:attr(data-placeholder);
  color:gray
}

span.clickable {
  text-decoration: underline;
  text-decoration-color: var(--error);
  -webkit-text-decoration-color: var(--error);
  cursor: pointer;
  scroll-margin-top: 70px;
}
span.clickable.important {
  text-decoration: underline;
	text-decoration-style:wavy;
  -webkit-text-decoration-style:wavy;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--error-important-underline);
  -webkit-text-decoration-color: var(--error-important-underline);
  cursor: pointer;
}
span.clickable:hover {
  background-color: var(--error);
}
span.clickable.selected {
  background-color: var(--error);
}
span.clickable.hovered {
  background-color: var(--error);
}

span.clickable.important:hover {
  background-color: var(--error);
}
span.clickable.important.selected {
  background-color: var(--error);
}
span.clickable.important.hovered {
  background-color: var(--error);
}


.popover.correction-menu {
  background: rgba(0, 0, 0, 0);
  border-width: 0px;
  box-shadow: 0px 0px 0px 0px;
}

#texteditor:empty:focus::before,
#texteditor:empty::before {
  content:attr(placeholder);
  color:grey;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
