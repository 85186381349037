:root {
  --green: #79BCAF;
  --green-for-text: #5EAA9B;
  --light-green: #f0fbf9;
  --mid-green: #E2F7F3;
  --orange: #FFA500;
  --red: #DC3545;
  --badge-red: #DC4C64;
  --light-gray: #fbfbfb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-green);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.orange-button {
  background: var(--orange) !important;
  border-radius: 8px !important;
}

.green-button {
  background: var(--green) !important;
  border-radius: 8px !important;
}

.btn-floating {
    border-radius: 50%!important;
}

.essay-item {
  background: #bdece3;
}

.gradient-bubble {
  border: 1px !important;
  border-style: solid !important;
  border-color: #999999 !important;

  box-shadow: 0px 4px 9px -4px #999999 !important;

  /* fallback for old browsers32`  */
  background: #666666;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #999999, #666666) !important;

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #999999, #666666) !important;
}

.gradient-bubble.outline {
  background: white !important;
  color: #666666 !important;
}

.signup-page-button {
  width: 166px;
}

.confirm-page-button {
  width: 200px;
}


.green-button:disabled {
  opacity: 0.3;
  filter: grayscale(100%);
  transition: all 100ms ease-in-out 0ms;
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  transition-delay: 0ms;
}

.green-button.outline {
  border: 1px !important;
  border-style: solid !important;
  background: white !important;
  color: var(--green-for-text) !important;
}

.green-button.rounded {
  border-radius: 16px !important;
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
    background-image: none;
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

.dropdown-menu {
  min-width: 1rem!important;
}

.caret-off::before {
    display: none!important;
}
.caret-off::after {
    display: none!important;
}

.navbar-light {
  background-color: var(--green) !important;
  border: none !important;
  border-width:0!important;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none!important;
}

.app-panel {
  background-color: var(--light-green) !important;
  min-height: 100vh;
}

.list-group-item {
  background-color: var(--light-green) !important;
}

.list-group-item.active {
  background-color: #d3f3ed  !important;
}

.list-group-item:hover {
  background-color: #d3f3ed  !important;
}

.half-sized {
  transform: rotate(20deg);
}

.two-line-ellipsis {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.left-page {
  position: absolute;
  width: 100%;
}

.light-green-page {
  background-color: var(--light-green);
}

.left-page-enter {
  transform: translate(-100%, 0%);;
}

.left-page-enter-active {
  transform: translate(0%, 0%);
  transition: transform 750ms;
}

.left-page-exit {
  transform: translate(0%, 0%);
}

.left-page-exit-active {
  transform: translate(-100%, 0%);
  transition: transform 750ms;
}

.right-page {
  position: absolute;
  width: 100%;
}

.right-page-enter {
  transform: translate(100%, 0%);;
}

.right-page-enter-active {
  transform: translate(0%, 0%);
  transition: transform 750ms;
}

.right-page-exit {
  transform: translate(0%, 0%);
}

.right-page-exit-active {
  transform: translate(100%, 0%);
  transition: transform 750ms;
}

.blockquote-footer:before {
  content:'' !important;
}

.blockquote-footer {
  font-size: 0.8em !important;
}

.beta {
  display: inline-block;
}

.beta::after {
   content: 'Beta';
   font-size: 20px;
   vertical-align: top;
   color: var(--mdb-body-color);
}

.floating-button-pane:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:linear-gradient(transparent, #ffffffdd, white);
}

li a {
  word-break: break-all;
}

.usage {
  font-size: 1.1em;
  border: 1px solid;
  border-color: var(--green);
  padding: 20px;
  background: white;
  width: fit-content;
}
.bubble {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 3px 12px;
  color: var(--green-for-text);
  font-size: 0.8em;
  background: var(--light-green);
  border: solid 1px var(--green);
  border-radius: 1em;
  height: fit-content;
  text-align: center;
  min-width: 5em;
}
@media only screen and (min-width: 576px) {
  .bubble{
    padding: 3px 15px;
    font-size: 1em;
  }
}
@media only screen and (min-width: 768px) {
  .bubble{
    padding: 3px 12px;
    font-size: 0.8em;
  }
}
@media only screen and (min-width: 992px) {
  .bubble{
    padding: 3px 15px;
    font-size: 1em;
  }
}

.recommendation-reason {
  font-size: 1.1em;
  display: inline-block;
  padding: 20px;
  width: fit-content !important;
  background: var(--light-green)
}

.reason-circle {
  position: relative;
  display: inline-block;
  --circle-size: 80px;
  width: var(--circle-size);
  height: var(--circle-size);
  background: white;
  border-radius: 50%;
}

.reason-circle span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
  width: var(--circle-size);
  text-align: center;
  color: var(--green-for-text);
}
@media only screen and (min-width: 576px) {
  .reason-circle {
    --circle-size: 110px;
  }
  .reason-circle span {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) {
  .reason-circle {
    --circle-size: 80px;
  }
  .reason-circle span {
    font-size: 13px;
  }
}
@media only screen and (min-width: 992px) {
  .reason-circle {
    --circle-size: 115px;
  }
  .reason-circle span {
    font-size: 16px;
  }
}


.reason-rectangle {
  text-align: center;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 7px 10px;
  color: white;
  font-size: 18px;
  background: var(--green);
  width: 100%;
}

.balloon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: var(--green-for-text);
  font-size: 16px;
  background: white;
  border: solid 2px var(--green);
  border-radius: 15px;
  width: fit-content;
}

.balloon:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -25px;
  margin-left: -14px;
  border: 12px solid transparent;
  border-top: 13px solid white;
  z-index: 2;
}

.balloon:after {
  content: "";
  position: absolute;
  bottom: -28px;
  left: 50%;
  margin-left: -15px;
  border: 13px solid transparent;
  border-top: 13px solid var(--green);
  z-index: 1;
}

.balloon p {
  margin: 0;
  padding: 0;
}

/* Rounded border */
hr.solid {
  border-top: 1px solid #bbb;
}

.wide {
  text-shadow: -0.4px 0, 0 0.4px, 0.4px 0, 0 -0.4px, -0.4px -0.4px, 0.4px 0.4px, -0.4px 0.4px, 0.4px -0.4px;
  font-family: "Verdana";
}

.col {
  max-width: 1200px!important;
}

span.new-type-badge {
  color: white !important;
  background-color: var(--badge-red) !important;
  font-weight: 500 !important;
  border-radius: 50% !important;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.fitting-alert {
  display: inline-block;
  padding: 0.5rem !important;
}

.user-menu-dropdown {
  z-index: 1110 !important;
}


.catch-copy-margin {
  --catch-margin-x-px: 20px;
  margin-left: var(--catch-margin-x-px) !important;
  margin-right: var(--catch-margin-x-px) !important;
}

.catch-copy-box {
  background-color: white;
  border-radius: 16px;
  --padding-x-rem: 0.5rem;
  padding-top: 1rem;
  padding-right: var(--padding-x-rem);
  padding-left: var(--padding-x-rem);
}

.catch-copy-ss {
  margin-top: 1rem !important;
  justify-content: center !important;
}
@media only screen and (min-width: 768px) {
  .catch-copy-ss {
    margin-top: 0 !important;
  }
}

.catch-copy-ss-vend {
  align-items: flex-end !important;
  display: flex !important;
  justify-content: center;
}

.catch-copy-ss-vcenter {
  align-items: center !important;
  display: flex !important;
  margin-bottom: 1rem;
  justify-content: center;
}

.camera-button-overlay {
  height: 0px;
}

.camera-button-overlay button{
  position: relative;
  transform: translateY(-120%);
}